:root {
  --curusLightBlue: #3c78fc;
  --curusBlue: #25337d;
  --brightCurusOrange: "#EA7C27";
}
.active {
  font-weight: 700;
  text-decoration: underline;
}
.nav-btn-active {
  color: var(--curusBlue);
}
@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans Black"),
    url("./assets/NunitoSans-Regular.ttf") format("truetype");
}
.phone-field {
  display: flex;
  font-size: 1rem;

  width: 100%;
  outline: none;
  line-height: 21px;
  border: 2px solid #dee3eb;
  border-radius: 5px;
  padding: 0.7rem 1rem;
  color: gray;
  transition: 0.1s ease-out;
}
@media screen and (max-width: 768px) {
  .phone-field {
    width: 100%;
  }
}
.phone-field input::placeholder {
  color: transparent;
}
.phone-field:focus {
  border: 2px solid #25337d;
}

input:focus + label {
  color: #25337d;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.phone-field input:not(:placeholder-shown) + label {
  top: 0;
  color: #25337d;
  transform: translateY(-50%) scale(0.9);
}

.pac-container {
  position: relative;
  width: 100%;
}

#kycFile {
  width: 0;
  height: 0;
  display: none;
}
#imgFile {
  width: 0;
  height: 0;
  display: none;
}
#dp-input {
  width: 0;
  height: 0;
  display: none;
}

.custom-shape-divider-top-1671968147 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 5;
}
@media screen and (max-width: 768px) {
  .custom-shape-divider-top-1671968147 {
    display: none;
  }
}
.custom-shape-divider-top-1671968147 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 140px;
}

.custom-shape-divider-top-1671968147 .shape-fill {
  fill: #4078fc;
}

[type="checkbox"] {
  height: 15px;
  width: 15px;
  margin-right: 0.7rem;
}
.hidden {
  display: none;
}
/* Spacing */
.m-1 {
  margin: 1rem;
}

.link {
  font-size: 14px;
  color: blue;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(89, 78, 255);
}
/* Modal Animation */
.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.2s ease-in-out;
}

/* .ReactModal__Content--after-open::-webkit-scrollbar {
  display: block;
  width: 5px;
}
.ReactModal__Content--after-open::-webkit-scrollbar-track {
  background: transparent;
}

.ReactModal__Content--after-open::-webkit-scrollbar-thumb {
  background-color: grey;
  border-right: none;
  border-left: none;
}

.ReactModal__Content--after-open::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 1rem;
}

.ReactModal__Content--after-open::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 1rem;
} */

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  /* transform: translateY(-20px); */
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

/* Canvas for drkmode toggle */
.canvas-size {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: -1;
  transition: background-image 500ms ease-in-out;
  /* background-image: url("./assets/dark-bg.svg"); */
}

.darkModeToggle {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  border: none transparent; /* none is required for IE11 Support */
  position: relative;
  cursor: pointer;
  padding: 0 8px;
  margin-top: -4px;
}

.crescent {
  position: absolute;
  border-radius: 100%;
  left: 8px;
  bottom: 8px;
  width: 1.2rem;
  height: 1.2rem;
  transform: scale(1);
  transition: transform 0.5s, background-color 0.5s;
}

.darkModeToggle:focus {
  outline: 0;
}

.sun {
  background: lightblue;
}

.sun .crescent {
  transform: scale(1);
  background: white;
}

.moon {
  background: #ea7c27;
}

.moon .crescent {
  transform: scale(0);
  background: #000000;
}

/* .moon:focus {
  background: lightgrey;
} */

.dark-bg {
  background-image: url("./assets/dark-bg.svg");
}
.light-bg {
  background-image: url("./assets/bg.svg");
}
